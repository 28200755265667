
module.exports = {
  'fr': [
    {
      id: 'howdoesitwork',
      locale: 'fr-fr',
      baseSlug: 'how-does-it-work',
      label: `Comment ça marche ?`,
      slug: 'fr/comment-ca-marche',
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/how-does-it-work/"}
      ],
      pages: [
        {
          id: 'demo',
          locale: 'fr-fr',
          baseSlug: 'demo',
          label: `Démo`,
          slug: 'fr/demo',
          alt_languages: [
            { langKey: "en", locale: "en-us", path: "/en/demo/" }
          ],
        },
        {
          id: 'download',
          locale: 'fr-fr',
          baseSlug: 'download',
          label: `Télécharger l'application`,
          slug: 'fr/telecharger',
          alt_languages: [
            { langKey: "en", locale: "en-us", path: "/en/download/" }
          ],
        },
        {
          id: 'faq',
          locale: 'fr-fr',
          baseSlug: 'faq',
          label: `FAQ`,
          slug: 'fr/faq',
          dist: '/faq/?language=fr',
          alt_languages: [
            { langKey: "en", locale: "en-us", path: "/en/faq/" }
          ],
        },
        {
          id: 'code',
          locale: 'fr-fr',
          baseSlug: 'code',
          label: `Activer un livre`,
          slug: 'fr/code',
          alt_languages: [
            { langKey: "en", locale: "en-us", path: "/en/code/" }
          ],
        },
        {
          id: 'contact',
          locale: 'fr-fr',
          baseSlug: 'contact',
          label: `Contact`,
          slug: 'fr/contact',
          alt_languages: [
            { langKey: "en", locale: "en-us", path: "/en/contact/" }
          ],
        }
      ]
    },
    {
      id: 'shop',
      locale: 'fr-fr',
      baseSlug: 'shop',
      label: `Livres de coloriages`,
      slug: 'fr/boutique',
      pagesTitle: 'Nos Livres', 
      pages: [], // data from prismic.io
      filters: [{
        api_id: 'age_filters',
        title: 'Par âge',
        content: null // data from prismic.io
      }],
      opts: {
        columns: 2
      },
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/shop/" }
      ],
    },
    {
      id: 'test',
      locale: 'fr-fr',
      baseSlug: 'test',
      label: `Tester gratuitement`,
      slug: 'fr/tester',
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/test/" }
      ],
    },
    {
      id: 'collaborations',
      template: 'collaborationsHome',
      locale: 'fr-fr',
      baseSlug: 'collaborations',
      label: `Collaborations`,
      slug: 'fr/collaborations',
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/collaborations/" }
      ],
    },
    // {
    //   id: 'education',
    //   baseSlug: 'education',
    //   label: `Éducation`,
    //   slug: 'education',
    //   pages: [
    //     {
    //       id: 'wakatoonstudio',
    //       baseSlug: 'wakatoon-studio',
    //       label: `Qu'est-ce Wakatoon Studio ?`,
    //       slug: 'education/wakatoon-studio'
    //     },
    //     {
    //       id: 'project',
    //       baseSlug: 'project',
    //       label: `Le projet pédagogique`,
    //       slug: 'education/projet'
    //     },
    //     {
    //       id: 'getstarted',
    //       baseSlug: 'get-started',
    //       label: `Commencer l'aventure`,
    //       slug: 'education/commencer'
    //     },
    //     {
    //       id: 'testimonials',
    //       baseSlug: 'testimonials',
    //       label: `Témoignages`,
    //       slug: 'education/testimonials'
    //     },
    //     {
    //       id: 'contact',
    //       baseSlug: 'contact',
    //       label: `Contact`,
    //       slug: 'contact'
    //     }
    //   ]
    // },
    {
      id: 'team',
      locale: 'fr-fr',
      baseSlug: 'team',
      label: `Équipe`,
      slug: 'fr/equipe',
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/team/" }
      ],
    },
    {
      id: 'blog',
      locale: 'fr-fr',
      baseSlug: 'blog',
      label: `Blog`,
      slug: 'fr/blog',
      alt_languages: []
    },
    {
      id: 'press',
      locale: 'fr-fr',
      baseSlug: 'press',
      label: `Espace presse`,
      slug: 'fr/presse',
      isFooterLink: true,
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/press/" }
      ],
    },
    {
      id: 'sitemap',
      locale: 'fr-fr',
      baseSlug: 'sitemap',
      label: `Plan du site`,
      slug: 'fr/plan-du-site',
      isFooterLink: true,
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/site-plan/" }
      ],
    },
    {
      id: 'legalnotice',
      locale: 'fr-fr',
      baseSlug: 'legal-notice',
      label: `Mentions légales`,
      slug: 'fr/mentions-legales',
      isFooterLink: true,
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/legal-notice/" }
      ],
    },
    {
      id: 'privacy',
      locale: 'fr-fr',
      baseSlug: 'privacy-policy',
      label: `Politique de confidentialité`,
      slug: 'fr/politique-de-confidentialité',
      isFooterLink: true,
      alt_languages: [
        { langKey: "en", locale: "en-us", path: "/en/privacy-policy/" }
      ],
    }
  ],
  'en': [
    {
      id: 'howdoesitwork',
      locale: 'en-us',
      baseSlug: 'how-does-it-work',
      label: `How does it work ?`,
      slug: 'en/how-does-it-work',
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/comment-ca-marche/" }
      ],
      pages: [
        {
          id: 'demo',
          locale: 'en-us',
          baseSlug: 'demo',
          label: `Demo`,
          slug: 'en/demo',
          alt_languages: [
            { langKey: "fr", locale: "fr-fr", path: "/fr/demo/" }
          ],
        },
        {
          id: 'download',
          locale: 'en-us',
          baseSlug: 'download',
          label: `Download app`,
          slug: 'en/download',
          alt_languages: [
            { langKey: "fr", locale: "fr-fr", path: "/fr/telecharger/" }
          ],
        },
        {
          id: 'faq',
          locale: 'en-us',
          baseSlug: 'faq',
          label: `FAQ`,
          slug: 'en/faq',
          dist: '/faq/?language=en',
          alt_languages: [
            { langKey: "fr", locale: "fr-fr", path: "/fr/faq/" }
          ],
        },
        // {
        //   id: 'code',
        //   baseSlug: 'code',
        //   label: `Activate a story`,
        //   slug: 'code',
        //   dist: '/code/'
        // },
        {
          id: 'contact',
          locale: 'en-us',
          baseSlug: 'contact',
          label: `Contact`,
          slug: 'en/contact',
          alt_languages: [
            { langKey: "fr", locale: "fr-fr", path: "/fr/contact/" }
          ],
        }
      ]
    },
    {
      id: 'shop',
      locale: 'en-us',
      baseSlug: 'shop',
      label: `Coloring stories`,
      slug: 'en/shop',
      pagesTitle: 'Our stories', 
      pages: [], // data from prismic.io
      filters: [{
        api_id: 'age_filters',
        title: 'By age',
        content: null // data from prismic.io
      }],
      opts: {
        columns: 2
      },
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/boutique/" }
      ],
    },
    {
      id: 'test',
      locale: 'en-us',
      baseSlug: 'test',
      label: `Free demo`,
      slug: 'en/test',
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/tester/" }
      ],
    },
    {
      id: 'collaborations',
      template: 'collaborationsHome',
      locale: 'en-us',
      baseSlug: 'collaborations',
      label: `Collaborations`,
      slug: 'en/collaborations',
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/collaborations/" }
      ],
    },
    // {
    //   id: 'education',
    //   baseSlug: 'education',
    //   label: `Éducation`,
    //   slug: 'education',
    //   pages: [
    //     {
    //       id: 'wakatoonstudio',
    //       baseSlug: 'wakatoon-studio',
    //       label: `Qu'est-ce Wakatoon Studio ?`,
    //       slug: 'education/wakatoon-studio'
    //     },
    //     {
    //       id: 'project',
    //       baseSlug: 'project',
    //       label: `Le projet pédagogique`,
    //       slug: 'education/projet'
    //     },
    //     {
    //       id: 'getstarted',
    //       baseSlug: 'get-started',
    //       label: `Commencer l'aventure`,
    //       slug: 'education/commencer'
    //     },
    //     {
    //       id: 'testimonials',
    //       baseSlug: 'testimonials',
    //       label: `Témoignages`,
    //       slug: 'education/testimonials'
    //     },
    //     {
    //       id: 'contact',
    //       baseSlug: 'contact',
    //       label: `Contact`,
    //       slug: 'contact'
    //     }
    //   ]
    // },
    {
      id: 'team',
      locale: 'en-us',
      baseSlug: 'team',
      label: `Team`,
      slug: 'en/team',
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/equipe/" }
      ],
    },
    // {
    //   id: 'blog',
    //   locale: 'en-us',
    //   baseSlug: 'blog',
    //   label: `Blog`,
    //   slug: 'blog'
    // },
    {
      id: 'press',
      locale: 'en-us',
      baseSlug: 'press',
      label: `Press`,
      slug: 'en/press',
      isFooterLink: true,
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/presse/" }
      ],
    },
    {
      id: 'sitemap',
      locale: 'en-us',
      baseSlug: 'site-plan',
      label: `Sitemap`,
      slug: 'en/site-plan',
      isFooterLink: true,
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/plan-du-site/" }
      ],
    },
    {
      id: 'legalnotice',
      locale: 'en-us',
      baseSlug: 'legal-notice',
      label: `Legal notice`,
      slug: 'en/legal-notice',
      isFooterLink: true,
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/mentions-legales/" }
      ],
    },
    {
      id: 'privacy',
      locale: 'en-us',
      baseSlug: 'privacy-policy',
      label: `Privacy policy`,
      slug: 'en/privacy-policy',
      isFooterLink: true,
      alt_languages: [
        { langKey: "fr", locale: "fr-fr", path: "/fr/politique-de-confidentialité/" }
      ],
    }
  ]
}
