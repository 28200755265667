/**
 * @todo Refactoring
 */

import React from 'react';
import { connect } from 'react-redux';
import { useStaticQuery, graphql, Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Newsletter from './Newsletter';
import Social from './Social';
import Style from '../styles/components/footer.module.scss'
import phoneIcon from '../assets/images/icon_rond_telephone.png';
import mailIcon from '../assets/images/icon_rond_mail_enveloppe.png';

const Footer = (props) => {
  
  const { columns } = useStaticQuery(
    graphql`
      query {
        columns: allPrismicCopyFootercolumns(filter: {slugs: {eq: "copyfootercolumns2020"}}) {
          edges {
            ...footerLinks
          }
        }
      }
    `
  );

  const footer = columns.edges.length > 1
    ? columns.edges.filter((edge) => edge.node.lang === (props.locale || 'fr-fr'))[0].node.data
    : columns.edges[0].node.data;
  
  const cols = parseInt(footer.footer_columns.filter((item) => JSON.parse(item.enabled)).length);
  
  const LinkWrapper = (props) => {
    if (!props.removable) {
      if (props.link.href === null) {
        return (props.phone || props.email) ? (
          <span className={Style.disabled}>
            <a href={props.phone ? 'tel:+33623016743' : 'mailto:sales@wakatoon.com'}>
              {props.link.label}
            </a>
          </span>
        ) : (
          <span className={Style.disabled}>{props.link.label}</span>
        )
      } else {
        const hashLink = props.link.href.split('&').filter(v => v.startsWith('#'));
        if (hashLink.length === 1) {
          if (hashLink[0] === '#download') {
            return (
              <span><Link 
                to={`/${props.lang}/${hashLink[0]}`} 
              >
                {props.link.label}
              </Link></span>
            )
          } else {
            return (
              <span><AnchorLink href={`${hashLink}`}>{props.link.label}</AnchorLink></span>
            )
          }
        } else {
          return (
            <span>
              <Link
                key={`footerLink_${props.index}`}
                to={`/${props.lang}/${props.link.href}/`}
              >
                {props.link.label}
              </Link>
            </span>
          )
        }
      }
    } else {
      return <span style={{display: 'none'}}></span>
    }
  }
  
  return (
    <React.Fragment>
      <div className={Style.container}>
        <div className="container" style={{ padding: 0 }}>
          <div className={`${Style.row} row`}>
            {footer.footer_columns.map((column) => {
              return JSON.parse(column.enabled) && (
                <div
                  key={`footerColumn_${column.column_id}`}
                  className={`${Style['col' + (12 / cols)]} col-${(12 / cols)} no-padding`}
                >
                  <div className={Style.footerLinksWrapper}>
                    <div>
                      <p 
                        key={`footerColumnTitle_${column.column_id}`}
                        style={{ display: `${(column.content__react_component_ === 'Newsletter' && props.hideMenu) ? 'none' : 'block' }`}}
                      >
                        {column.column_name.text}
                      </p>
                      { column.content__prismic_.document[0].data.footer_links.map((item, index) => {
                        return JSON.parse(item.enabled) && (
                          JSON.parse(item.new_window) ? (
                            <span 
                              key={`footerLink_${index}`} 
                              style={{display: `${(props.hideMenu && item.removable) ? 'none' : 'block'}`}}>
                              <a
                                href={`/${item.link}/${item.link === 'faq' ? `?language=${props.langKey}` : ''}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.label.text}
                              </a>
                            </span>
                          ) : (
                            <div key={`link-wrapper-${index}`}>
                              {column.column_id === 'column2' ? (
                                <div className={Style.columnIcon}>
                                  {index === 0 ? (
                                    <div><img alt="icon-phone" src={phoneIcon} /></div>
                                  ) : (
                                    <div><img alt="icon-email" src={mailIcon} /></div>
                                  )}
                                  <LinkWrapper
                                    link={{ href: item.link, label: item.label.text }}
                                    index={index}
                                    lang={props.langKey}
                                    removable={(props.hideMenu && item.removable)}
                                    phone={index === 0}
                                    email={index !== 0}
                                  />
                                </div>
                              ) : (
                                <LinkWrapper 
                                  link={{ href: item.link, label: item.label.text }}
                                  index={index}
                                  lang={props.langKey}
                                  removable={(props.hideMenu && item.removable)}
                                />
                              )}
                            </div>
                          )
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={`${Style.row} row`}>
            <div 
              className={`${Style['col' + (12 / cols)]} col-${(12 / cols)} no-padding`} 
              style={{display: `${props.hideMenu ? 'none' : 'block'}`}}
            >
              <div className={Style.footerLinksWrapper}>
                <div>
                  <p>Newsletter</p>
                  <Newsletter api={props.api} _t={props._t} removable={props.hideMenu} />
                </div>
              </div>
            </div>
            <div className={`${Style['col' + (12 / cols)]} col-${(12 / cols)} no-padding`}>
              <div className={Style.footerLinksWrapper}>
                <div>
                  <p>Suivez-nous</p>
                  <Social {...footer.social_block} />
                </div>
              </div>
            </div>
            <div className={`${Style['col' + (12 / cols)]} col-${(12 / cols)} no-padding`}>
              <div className={Style.footerLinksWrapper}>
                <div></div>
              </div>
            </div>
            <div className={`${Style['col' + (12 / cols)]} col-${(12 / cols)} no-padding`}>
              <div className={`${Style.footerLinksWrapper} ${Style.copy}`}>
                <div>
                  &copy; {`2014-${new Date().getFullYear()} Pixpole`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(Footer);