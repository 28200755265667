import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

/**
 * Prismic Static Texts HOC
 */
const StaticTextsPrepend = (WrappedComponent, pageContext) => {
  return class extends React.Component {
    render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              Texts: allPrismicGlobalStatictexts {
                edges {
                  ...StaticTexts
                }
              }
            }
          `}
          render={(data) => {
            const _locale = pageContext.locale;
            const _data   = data.Texts.edges;
            const _items  = _data.filter((item) => item.node.lang === _locale)[0].node.data.texts;
            const _texts  = {};
            const addContent = this.props.addContent && { addContent: this.props.addContent };
            
            _items.forEach((item) => {
              _texts[item.key_text] = item.text;
            });

            return <WrappedComponent {..._texts} {...addContent} />
          }}
        />
      );
    }
  }
}

export default StaticTextsPrepend;
