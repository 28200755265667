
export const handleInitCart = (client) => ({
  type: 'INIT_SHOPIFY_CLIENT',
  client
});

export const checkoutFound = (gqlRes) => ({
  type: 'CHECKOUT_FOUND',
  res: gqlRes,
  checkoutFound: true
});

export const addVariantToCart = (gqlRes, open = true) => ({
  type: 'ADD_VARIANT_TO_CART',
  res: gqlRes,
  isCartOpen: open
});

export const localAddVariantToCart = (references, open = true) => ({
  type: 'LOCAL_ADD_VARIANT_TO_CART',
  references,
  isCartOpen: open
});

export const flushLocalCartVariant = () => ({
  type: 'FLUSH_LOCAL_VARIANT'
});

export const updateQuantityInCart = (gqlRes) => ({
  type: 'UPDATE_QUANTITY_IN_CART',
  res: gqlRes
});

export const removeLineItemInCart = (gqlRes) => ({
  type: 'REMOVE_LINE_ITEM_IN_CART',
  res: gqlRes
});

export const handleCartClose = () => ({
  type: 'CLOSE_CART'
});

export const handleCartOpen = () => ({
  type: 'OPEN_CART'
});