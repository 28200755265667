import React from 'react'
import Style from '../styles/components/locale.module.scss'
import { StaticQuery, graphql } from 'gatsby'


const _LocaleSwitch = ({ handleSubMenu, ui, context: { langKey } }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          flags: allFile(filter: { relativeDirectory: { eq: "locale" } }) {
            edges {
              node {
                name
                childImageSharp {
                  fixed(width: 29, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={({ flags: { edges: items } }) => {
        const current = items.filter((item) => {
          return item.node.name === `flag_${langKey || 'fr'}_${ui}_off`
        });
        return (
          <>
            {
              ui === 'desktop' ? (
                <button className={Style.localeSwitchButton} onMouseOver={handleSubMenu}>
                  <img data-slug="locale" src={current[0].node.childImageSharp.fixed.src} alt="" />
                </button>
              ) : (
                  <button className={Style.localeSwitchButton} onClick={handleSubMenu}>
                  <img data-slug="locale" src={current[0].node.childImageSharp.fixed.src} alt="" />
                </button>
              )
            }
          </>
        )
      }}
    />
  )
}

export const LocaleSwitch = _LocaleSwitch;
