/**
 * @todo refactoring
 */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import * as EmailValidator from 'email-validator'
import Modal from 'react-modal'
import { handleModal } from '../../actions/modal'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import StaticTextsPrepend from '../StaticTextsPrepend'
import Close from '../../assets/images/close.png'

const _PrismicToStaticContent = (props) => {
  const rawContent = props[`Pour recevoir gratuitement le pdf de l'histoire [...]`].text;
  const content = rawContent.replace('{story_name}',`<span>${props.addContent}</span>`);
  return (
    <p className="modal__body" dangerouslySetInnerHTML={{__html: content}}></p>
  );
}

class ModalPopUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sending: false
    }
    const staticData = props.storyTemplate.edges;
    const emailTemplate = staticData.filter((edge) => edge.node.lang === props.context.locale);
    this.emailTemplate = emailTemplate.length > 0 ? emailTemplate[0].node.data : [];
  }

  handleSendEmail = (e) => {
    e.preventDefault();

    const _self = this;
    const email = e.target.elements.email.value.trim();
    const api_endpoint = this.props.api.endpoint;
    
    if (!EmailValidator.validate(email)) {
      NotificationManager.error(
        this._t('Veuillez saisir une adresse E-mail valide.'), 
        'Erreur', 3000
      );
      return false;
    }
    this.setState({ sending: true });

    fetch(`${api_endpoint}/${this.props.context.locale}/${this.props.story.projectId}?email=${email}`, {
      headers: {
        "X-Api-Key": this.props.api.key
      }
    })
      .then(results => results.json())
      .then(json => {
        if (typeof json === 'object') {
          if (json.success) {
            NotificationManager.success(
              `${this._t(`Un lien de téléchargement vous a été envoyé à l'adresse`)} ${email}.`,
              '', 3000
            );
          } else {
            NotificationManager.error(
              this._t('Une erreur est survenue.'),
              'Erreur', 3000
            );
          }
        } else {
          NotificationManager.error(
            this._t('Une erreur est survenue.'),
            'Erreur', 3000
          );
        }
        _self.setState({ sending: false });
        setTimeout(function () { _self.handleCloseModal() }, 3000);
      });
  }

  handleCloseModal = () => {
    this.props.handleModal(false);
  }

  _t = (str) => {
    return this.props._t[this.props._t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    const PrismicToStaticContent = StaticTextsPrepend(_PrismicToStaticContent, this.props.context);
    const PrintablesModalsContent = (props) => {
      if (!props.story.isBook) {
        return (
          <PrismicToStaticContent {...{ addContent: props.story.name }} />
        )
      } else {
        return (
          <PrismicToStaticContent {...{addContent: props.story.name}} />
        )
      }
    }

    const PopupBackground = () => (
      <div>
        {this.props.story.popupBackground.url &&
          <div className={
            this.props.story.projectId === '0056' 
            ? `modal-background modal-background-31` 
            : `modal-background`
          }>
            <img src={this.props.story.popupBackground.url} alt={this.props.story.name} />
          </div>
        }
      </div>
    );
    
    const trackingId = (story) => {
      return `waka-get-printable-pdf${story.pageTracking ? `-${story.pageTracking}` : ''}-${story.projectId}`;
    }

    return (
      <Modal
        isOpen={this.props.modal}
        contentLabel="Selected option"
        closeTimeoutMS={0}
        className="modal modal-books"
        ariaHideApp={false}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        {this.props.story && <PopupBackground />}
        <div 
          className="close-modal" 
          onClick={this.handleCloseModal}
          style={{backgroundImage:`url(${Close})`}}
        >
        </div>
        <div className="modal-container">
          {this.props.story && <PrintablesModalsContent {...this.props} />}
          <form onSubmit={this.handleSendEmail}>
            <input type="email" name="email" placeholder={this._t('Votre e-mail')} />
            <button 
              disabled={this.state.sending}
              className="button btn btn_default"
              id={this.props.story ? trackingId(this.props.story) : 0}
            >
              {!this.state.sending ? this._t('Valider') : this._t('Envoi en cours')}
            </button>
          </form>
        </div>
        <NotificationContainer />
      </Modal>
    );
  }
}

/**
 * StoryPopupPrepend
 * 
 * Get printable email template
 * & send it to the popup component
 */
const StoryPopupPrepend = (props) => (
  <StaticQuery
    query={graphql`
      query {
        storyTemplate: allPrismicEmailtemplatePrintables {
          edges {
            ...storyTemplate
          }
        }
      }
    `}
    render={data => (
      <ModalPopUp {...props} {...data} />
    )}
  />
);

const mapStateToProps = (state) => ({
  ...state.modal, ...state.mandrill
});
const mapDispatchToProps = (dispatch) => ({
  handleModal: (state) => dispatch(handleModal(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(StoryPopupPrepend);