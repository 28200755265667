import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { handleModalBanner } from '../../actions/modal'
import Slider from 'react-slick'
import { CustomPrevArrow, CustomNextArrow } from '../Slick/CustomArrows'
import Close from '../../assets/images/close.png'

class ModalBanner extends React.Component {

  handleCloseModal = () => {
    this.props.handleModalBanner(false);
  }
  render() {

    const dots = this.props.viewportSize !== 'large';

    const settings = {
      prevArrow: <CustomPrevArrow type="banner" />,
      nextArrow: <CustomNextArrow type="banner" />,
      adaptiveHeight: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide:this.props.initialSlide || 0, 
      dots: dots
    };

    return (
      <Modal
        isOpen={this.props.modalBanner}
        contentLabel="Selected option"
        closeTimeoutMS={0}
        className="modal modal-banners"
        ariaHideApp={false}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        <div 
          className="close-modal" 
          onClick={this.handleCloseModal}
          style={{backgroundImage:`url(${Close})`}}
        >
        </div>
        {
          this.props.images &&
          <Slider {...settings}>
            {this.props.images.map((item, index) => {
              return (
                <div key={index}>
                  <img alt={item.image_alt_attribute} src={item.page.url} />
                </div>
              )
            })}
          </Slider>
        }
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.modalBanner, ...{ viewportSize: state.viewport.viewportSize }
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalBanner);