import React from "react"
import { connect } from 'react-redux'

const Image = function (props) {
  const init = '/resize=2p/quality=50/';
  const filter = 'filtered-image';
  const transform = props.transparent ? 'auto/quality=80' : 'auto/background=white/quality=80';
  const prismicS3 = 'https://wakatoon.cdn.prismic.io/wakatoon/';
  let src = props.src && props.src.replace('images.prismic.io', 'wakatoon.cdn.prismic.io');
  src = src && src.replace('%2F', '/');
  src = src && src.replace('?auto=compress,format', '');
  return (
    <React.Fragment>
      { src ? (
        <React.Fragment>
          {
            (props.env === 'development' || props.regular) ? (
              <img 
                src={`${props.prismic ? `${prismicS3}${props.src}` : src}`} alt={props.alt} 
                className={`${props.className ? props.className : ''}`}
              />
            ) : (
              <>
                { props.prismic ? (
                    <img 
                      src={`${prismicS3}${props.src}`} alt={props.alt} 
                      className={`${props.className ? props.className : ''}`}
                    />
                ) : (
                  <img
                    src={`${props.force ? `https://wakatoon.twic.pics/v1/${src}` : `https://wakatoon.twic.pics/v1${init}${src}`}`}
                    data-src={src}
                    data-src-step="100"
                    data-src-transform={transform}
                    className={`twic ${props.className ? props.className : filter}`}
                    alt={props.alt}
                    style={props.style}
                  />
                )}
              </>
            )
          }
        </React.Fragment>
      ) : (
        <span></span>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({ ...state.loaded });
export default connect(mapStateToProps)(Image);