import React from "react"
import { connect } from 'react-redux';
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

function SEO(props) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        
        // Prismic graphql data
        const gqlData = data.prismicMetaContent.edges.filter((edge) => edge.node.lang === props.context.locale)
        const metadata = gqlData.length > 0 ? gqlData[0].node.data : []

        // gatsby-config.js graphql data
        const url = data.site.siteMetadata.siteUrl
        const lang = props.context ? (props.context.langKey || data.site.siteMetadata.lang) : data.site.siteMetadata.lang

        // const locale = props.context.locale;
        const title = props.pageTitle ? props.pageTitle : metadata.site_title_prefix.text
        const description = props.pageDescription ? props.pageDescription : metadata.description.text

        // page context
        const context = props.context === undefined ? {} : props.context
        
        // alt languages
        const alt_languages = context.alt_languages.length > 0 ? context.alt_languages.map((i) => {
          return { rel: "alternate", href: `${url}${i.path}`, hreflang: i.langKey }
        }) : [];

        // TwicPics
        const twicpicsScript = 'https://wakatoon.twic.pics/v1/script';
        const twicpics = props.env === 'development' ? {} : { src: twicpicsScript, rel: 'preload' }
        
        // Helmet settings
        const defaultImage = 'https://images.prismic.io/wakatoon/05a9174b-220e-4a6a-bf85-f74e6cafba5c_Wakatoon_MIL0793-1-HD.jpg';
        const helmetSettings = {
          title: props.ogTitle || title,
          meta: [
            { name: 'viewport', content:'width=device-width, initial-scale=1, maximum-scale=1' },
            { name: 'description', content: props.ogDescription || description },
            { property: 'og:title', content: props.ogTitle || title },
            { property: 'og:description', content: props.ogDescription || description },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: `${url}${context.slug}` },
            { property: 'og:locale', content: lang },
            { property: 'og:image', content: props.ogImage || defaultImage },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: props.ogTitle || title },
            { name: 'twitter:description', content: props.ogDescription || description },
            { name: 'twitter:image', content: props.ogImage || defaultImage },
            { "http-equiv": "content-language", content: lang }
          ],
          link: [
            (
              context.canonical 
                ? { rel: 'canonical', href: `${url}${context.slug}` } 
                : ( context.slug === '/' ? { rel: 'canonical', href: `${url}/` } : {} )
            ),
            (
              (context.slug === '/fr/' || context.slug === '/en/') ? (
                { rel: 'alternate', href: `${url}/`, hreflang: "x-default" } 
              ) : {}
            ),
            { rel: "alternate", href: `${url}${context.slug}`, hreflang: lang },
            ...alt_languages
          ],
          script: [twicpics]
        }
        if (!props.context.sitemap) {
          helmetSettings.meta.push(
            { "name": "robots", content: "noindex" },
          );
        }
        return (
          <Helmet {...helmetSettings}>
            <html lang={`${lang}`} />
          </Helmet>
        )
      }}
    />
  )
}

const mapStateToProps = (state) => ({
  ...state.loaded
});
export default connect(mapStateToProps)(SEO);

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        lang
      }
    }
    prismicMetaContent: allPrismicSiteMeta {
      edges {
        node {
          lang
          data {
            site_title_prefix {
              text
            }
            description {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
  }
`
