import React from 'react'
import Style from '../styles/components/social.module.scss'

export default (props) => {
  const items = props.document && props.document[0].data.links;
  return (
    <div className={Style.container}>
      { items && items.map((item, index) => {
        return (
          <a 
            key={`link-${index}`} 
            href={item.link} 
            target="_blank" 
            title={item.label.text} 
            rel="noopener noreferrer"
            style={{display:"inline"}}
          >
            <i className={`${Style.socialIcons} ${Style['icon_' + item.label.text.split(' ')[1]]}`} />
          </a>
        )
      })}
    </div>
  );
}
