import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import slugify from 'slugify'
import illustrations from '../../config/menu-illustrations'
import Image from './Images'
import SwitchLocaleMenu from './SwitchLocaleMenu'
import Style from '../styles/components/nav.module.scss'

export default class extends React.Component {

  constructor(props) {
    super(props);
    const { items, lang } = props;
    this.items = items;
    this.items.forEach((item) => {
      if (typeof illustrations[lang || 'fr'][item.id] !== 'undefined') {
        this.items[this.items.findIndex(_item => _item.id === item.id)].illustrations = illustrations[lang || 'fr'][item.id];
      }
    });
    this.state = { 
      current: undefined,
      locale: this.props.locale
    }
  }

  componentDidUpdate() {
    if (this.state.locale !== this.props.locale) {
      this.setState({locale: this.props.locale});
      const { items, lang } = this.props;
      this.items = items;
      this.items.forEach((item) => {
        if (typeof illustrations[lang || 'fr'][item.id] !== 'undefined') {
          this.items[this.items.findIndex(_item => _item.id === item.id)].illustrations = illustrations[lang || 'fr'][item.id];
        }
      });
    }
  }

  formatData = (data, locale) => {
    Object.keys(data).forEach((x) => {
      let menuIndex = this.items.findIndex(item => item.id === x);
      let filters = {};

      this.items[menuIndex].pages = data[x].edges.filter((edge) => edge.node.lang === locale).map((item) => {
        let slug = item.node.uid;
        let label = item.node.data.short_title ? item.node.data.short_title : item.node.data.title;

        if (typeof this.items[menuIndex].filters !== 'undefined') {
          this.items[menuIndex].filters.forEach((f, index) => {
            let api_id = f.api_id;

            if (typeof item.node.data[api_id] !== 'undefined') {
              item.node.data[api_id].forEach((d) => {
                let _filterLabel = d.filter.document[0].data.label
                let _filterSlugParam = slugify(_filterLabel, { remove: /[*+~.()'"?!:@]/g, lower: true });

                filters[_filterSlugParam] = {
                  label: _filterLabel,
                  param: _filterSlugParam,
                  anchor: _filterSlugParam,
                  parent: this.items[menuIndex].slug
                };
              });
            }
            this.items[menuIndex].filters[index].category = this.items[menuIndex].filters[index].title;
            this.items[menuIndex].filters[index].content = filters;
            this.items[menuIndex].filters[index].filters = filters;
          })
        }
        return {
          label: label.text,
          slug: `${item.node.data.project_id}/${slug}`,
          parent: this.items[menuIndex].slug
        }
      });
    });
  }

  handleSubMenu = (slug) => {
    if (slug === 'locale') {
      this.setState({ current: 'locale' })
      this.props.handleSubMenuOpened(true);
      return true;
    } else {
      const item = this.items[this.items.findIndex(item => item.slug === slug)];
      const _slug = item.pages ? item.slug : (item.id === 'collaborations' ? item.slug : undefined)
      this.setState({ current: _slug })
      this.props.handleSubMenuOpened(typeof _slug !== 'undefined');
      return typeof _slug !== 'undefined'
    }
  }

  handleMouseLeave = () => {
    this.setState({ current: undefined })
    this.props.handleBackgroundMenuActive(window.scrollY > 50);
    this.props.handleSubMenuOpened(false);
  }

  render() {
    const { locale } = this.props;
    const LinksListingColumns = (props) => (
      <ul className={`columns-${props.opts.columns}`}>
        {props.pages.map((page, index) => {
          return (
            <li key={`page-${index}`}>
              {typeof page.dist === 'undefined' ? (
                <Link to={page.parent ? `/${page.parent}/${page.slug}/` : `/${page.slug}/`}>
                  {page.label}
                </Link>
              ) : (
                  <a 
                    href={`${page.dist}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {page.label}
                  </a>
                )}
            </li>
          )
        })}
      </ul>
    );
    const LinksListing = (props) => (
      <ul style={props.illustrations.length === 1 ? { paddingLeft: 200 } : { paddingLeft: 50 }}>
        {props.pages.map((page, index) => {
          return (
            <li key={`page-${index}`}>
              {typeof page.dist === 'undefined' ? (
                <Link to={page.parent ? `/${page.parent}/${page.slug}/` : `/${page.slug}/`}>
                  {page.label}
                </Link>
              ) : (
                  <a 
                    href={`${page.dist}${page.slug === 'faq' ? `?language=${this.props.lang}` : ''}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {page.label}
                  </a>
                )}
            </li>
          )
        })}
      </ul>
    );
    
    const FiltersListing = (props) => (
      <ul>
        {Object.keys(props).map((categoryIndex) => {
          return (
            <div key={`filter-category-${categoryIndex}`}>
              <span>{props[categoryIndex].title}</span>
              {Object.keys(props[categoryIndex].content).sort().map((filter, filterIndex) => {
                let _filter = props[categoryIndex].content[filter];
                return (
                  <li key={`filter-${filterIndex}`}>
                    <a href={`/${_filter.parent}/?f=${filter}`}>
                      {_filter.label}
                    </a>
                  </li>
                )
              })}
            </div>
          )
        })}
      </ul>
    )

    return (
      <StaticQuery
        query={graphql`
          query {
            shop: allPrismicStory(
              filter: {
                data: {
                  visible: { eq: "true" }
                  wakatoon_studio: { eq: "false" }
                }
              }
            ) {
              edges {
                node {
                  uid
                  lang
                  data {
                    title {
                      text
                    }
                    short_title {
                      text
                    }
                    project_id
                    age_filters {
                      filter {
                        document {
                          data {
                            label
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          this.formatData(data, locale);
          const { collaborationsFilters } = this.props
          collaborationsFilters.sort((a,b) => (a.rank > b.rank) ? 1 : ((b.rank > a.rank) ? -1 : 0));
          return (
            <React.Fragment>
              {this.state.current === 'locale' ? (
                <div
                  className={Style.dropdownMenuContainer}
                  style={{ borderBottom: '2px solid black' }}
                  onMouseLeave={this.handleMouseLeave}
                >
                  <SwitchLocaleMenu
                    ui="desktop"
                    handleSubMenuOpened={this.props.handleSubMenuOpened}
                    context={this.props.context}
                  />
                </div>
              ) : (
                <div
                  className={Style.dropdownMenuContainer}
                  onMouseLeave={this.handleMouseLeave}
                >
                  {this.items.map((item, key) => {
                    return (
                      <div key={`dropdown-${key}`}>
                        {
                          item.id === 'collaborations' ? (
                            <div
                              className={`${Style.dropdownMenu} ${
                                this.state.current === item.slug
                                  ? Style.active
                                  : ''
                              }`}
                            >
                              <div>
                                <div className={`row`}>
                                  {collaborationsFilters.map((category, index) => {
                                    return (
                                      <div key={`category-${index}`} className={`col-md-auto`}>
                                        <span style={{color: category.color}}>{category.name}</span>
                                        {
                                          category.filters.length > 0 && (
                                            <ul>
                                              {
                                                category.filters.map((item, _index) => {
                                                  return (
                                                    <li key={`category-filter-${_index}`}>
                                                      <Link to={`/${this.props.lang}/collaborations/${item.uid}/`}>
                                                        {item.label}
                                                      </Link>
                                                    </li>
                                                  )
                                                })
                                              }
                                            </ul>
                                          )
                                        }
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <React.Fragment>
                              {item.pages && (
                                <div
                                  className={`${Style.dropdownMenu} ${
                                    this.state.current === item.slug
                                      ? Style.active
                                      : ''
                                  }`}
                                >
                                  <div>
                                    {item.illustrations &&
                                      item.illustrations.length === 2 && (
                                        <div>
                                          <Image
                                            style={{ width: '200px' }}
                                            src={`https://wakatoon.cdn.prismic.io/wakatoon/${item.illustrations[1]}`}
                                          />
                                        </div>
                                      )}
                                    <div className={Style.middleColumn}>
                                      {item.filters ? (
                                        <div className={Style.navColumns}>
                                          <div>
                                            {item.pagesTitle && (
                                              <span>{item.pagesTitle}</span>
                                            )}
                                            <LinksListingColumns {...item} />
                                          </div>
                                          <div className={Style.filters}>
                                            <FiltersListing {...item.filters} />
                                          </div>
                                        </div>
                                      ) : (
                                        <LinksListing {...item} />
                                      )}
                                    </div>
                                    {item.illustrations && (
                                      <React.Fragment>
                                        {item.illustrations.length === 2 ? (
                                          <div>
                                            <Image
                                              style={{ width: '100px' }}
                                              src={`https://wakatoon.cdn.prismic.io/wakatoon/${item.illustrations[0]}`}
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            <Image
                                              style={{ width: '400px' }}
                                              src={`https://wakatoon.cdn.prismic.io/wakatoon/${item.illustrations[0]}`}
                                            />
                                          </div>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          )
                        }
                      </div>
                    )
                  })}
                </div>
              )}
            </React.Fragment>
          )
        }}
      />
    )
  }
}
