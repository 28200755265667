import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
// import { navigate } from 'gatsby';
import App from '../components/App';
import { setHomepage } from '../actions/homepage'

const settingsQuery = graphql`
  query DefaultLayoutQuery {
    website: site {
      siteMetadata {
        siteUrl
        title
        lang
        logo
      }
    }
    prismicStaticContents: allPrismicGlobalStatictexts {
      edges {
        node {
          lang
          data {
            texts {
              key_text
              text {
                html
                text
              }
            }
          }
        }
      }
    }
    featureFiltersCollaborations: allPrismicFeatureFiltersCollaborations {
      edges {
        node {
          lang
          data {
            anchor
            label {
              text
            }
            category {
              uid
              document {
                data {
                  category_name {
                    text
                  }
                  color
                  rank
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`
const Layout = ({ children, ...props }) => (
  <StaticQuery
    query={settingsQuery}
    render={(data) => {
      const staticTexts = data.prismicStaticContents.edges;
      const translatedStaticTexts = staticTexts[staticTexts.findIndex(i => i.node.lang === props.context.locale)];
      
      const filtersCategories = []
      const { featureFiltersCollaborations } = data
      featureFiltersCollaborations.edges.filter(edge => edge.node.lang === props.context.locale).forEach((filter) => {
        filtersCategories[filter.node.data.category.uid] = filter.node.data.category.document[0].data
      });

      /* init collaborations filters */
      const collaborationsFilters = Object.keys(filtersCategories).map((item) => {
        return {
          uid: item,
          color: filtersCategories[item].color,
          name: filtersCategories[item].category_name.text,
          rank: filtersCategories[item].rank,
          filters: featureFiltersCollaborations.edges
          .filter(edge => edge.node.lang === props.context.locale)
          .filter(edge => edge.node.data.category.uid === item).map((filter) => {
            return {
              uid: filter.node.uid,
              anchor: filter.node.data.anchor,
              label: filter.node.data.label.text,
            }
          })
        }
      });
      
      const title = props.pageTitle || 'Wakatoon';
      const appSettings = {
        hideMenu: props.hideMenu,
        collaborationsFilters: collaborationsFilters,
        path: props.context.slug,
        logo: data.website.siteMetadata.logo,
        context: props.context,
        children: children,
        title: `${data.website.siteMetadata.title}${title ? `, ${title.toLowerCase()}` : ''}`,
        prismicStaticContents: translatedStaticTexts,
        isDownloadPage: typeof props.context.isDownloadPage !== 'undefined',
        _t: props._t || []
      }
      return <App {...appSettings} />
    }}
  />
);

const mapDispatchToProps = (dispatch) => ({
  setHomepage: (state) => dispatch(setHomepage(state))
});

export default connect(null, mapDispatchToProps)(Layout);