import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import * as EmailValidator from 'email-validator'
import Modal from 'react-modal'
import { handleModalCta } from '../../actions/modal'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import Close from '../../assets/images/close.png'

class ModalPopUpCta extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sending: false
    }
  }

  handleSendEmail = (e) => {
    e.preventDefault();

    const _self = this;
    const email = e.target.elements.email.value.trim();
    const { endpoint, key } = this.props.api;
    
    if (!EmailValidator.validate(email)) {
      NotificationManager.error(
        this._t('Veuillez saisir une adresse E-mail valide.'), 
        'Erreur', 3000
      );
      return false;
    }

    const data = {
      email: email,
      ...this.props.data
    }

    this.setState({ sending: true });
    axios.post(endpoint, data, {
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": key
      }
    }).then(function (json) {
      const { data } = json
      if (typeof data === 'object') {
        if (data.success) {
          NotificationManager.success(`${_self._t(`Votre demande a bien été prise en compte`)}.`,'', 3000);
        } else {
          NotificationManager.error(_self._t('Une erreur est survenue.'),'Erreur', 3000);
        }
      } else {
        NotificationManager.error(_self._t('Une erreur est survenue.'),'Erreur', 3000);
      }
      setTimeout(function () { 
        _self.setState({ sending: false });
        _self.handleCloseModal() 
      }, 3000);
    }).catch(function (error) {
      console.log('ERROR 116', error)
      setTimeout(function () { 
        _self.setState({ sending: false });
        _self.handleCloseModal() 
      }, 3000);
    });
  }

  handleCloseModal = () => {
    this.props.handleModalCta(false);
  }

  _t = (str) => {
    return this.props._t[this.props._t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalcta}
        contentLabel="Selected option"
        closeTimeoutMS={0}
        className="modal modal-books"
        ariaHideApp={false}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        <div 
          className="close-modal" 
          onClick={this.handleCloseModal}
          style={{backgroundImage:`url(${Close})`}}
        >
        </div>
        <div className="modal-container">
          <p className="modal__body">{this._t('Veuillez saisir votre adresse email afin que nous vous recontactions')}</p>
          <form onSubmit={this.handleSendEmail}>
            <input type="email" name="email" placeholder={this._t('Votre e-mail')} />
            <button 
              disabled={this.state.sending}
              className="button btn btn_default"
            >
              {!this.state.sending ? this._t('Valider') : this._t('Envoi en cours')}
            </button>
          </form>
        </div>
        <NotificationContainer />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.modalcta
});
const mapDispatchToProps = (dispatch) => ({
  handleModalCta: (state) => dispatch(handleModalCta(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalPopUpCta);