
export const handleChangeLocale = (locale) => ({
  type: 'CHANGE_LOCALE',
  locale: locale,
  lang: locale.split('-')[0]
});

export const getStaticContents = (contents) => ({
  type: 'GET_CONTENTS',
  ...contents
});
