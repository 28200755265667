/**
 * @todo translate
 */
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import Image from './Images'
import { handleCartOpen } from '../actions/cart'
import { handleBackgroundMenuActive } from '../actions/menu'
import { handleSubMenuOpened } from '../actions/menu'
import MainMenu from './Menu'
import Announcement from './Announcement';
import Style from '../styles/components/header.module.scss'

class Header extends React.Component {

  componentDidMount() {
    this.manageLayout();
  }

  componentDidUpdate() {
    this.manageLayout()
  }

  manageLayout = () => {
    const parentNode = ReactDOM.findDOMNode(this).parentNode;
    if (this.props.viewportSize === 'large') {
      
      this.wrapper = parentNode.querySelector(`.${Style.container}`);

      if (parentNode.querySelector('.home-banner') !== null) {
        window.addEventListener('load', this.handleLoad);
        setTimeout(() => {
          this.HomeBannerHeight = parentNode.querySelector('.home-banner').clientHeight;
          window.addEventListener('scroll', this.handleScroll, { passive: true });
          if (window.scrollY > 50) {
            this.wrapper.className = `${Style.container} ${Style.black}`;
          } else {
            if (!this.props.subMenuOpened && this.props.context.uid !== 'blog') {
              this.wrapper.className = `${Style.container}`;
            }
          }
        }, 500);
      } else {
        this.wrapper.className = `${Style.container} ${Style.black}`;
      }
      if (this.props.context.uid === 'blog') {
        this.wrapper.className = `${Style.container} ${Style.black}`;
      }
    }
  }

  handleLoad = (e) => {
    if (e.srcElement.scrollingElement.scrollTop > (this.HomeBannerHeight - 380)) {
      this.wrapper.className = `${Style.container} ${Style.black}`;
    }
  }

  handleScroll = (e) => {
    if (e.srcElement.scrollingElement.scrollTop > (this.HomeBannerHeight - 380)) {
      this.wrapper.className = `${Style.container} ${Style.black}`;
    } else {
      if (!this.props.subMenuOpened && this.props.context.uid !== 'blog') {
        this.wrapper.className = Style.container;
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={`${Style.container} ${(this.props.subMenuActive || this.props.context.uid === 'blog') ? Style.black : ''}`}>
          <Announcement context={this.props.context} />
          <div className={`${Style.header} ${Style.mainHeader}`}>
            <div className={Style.logo}>
              <Link to={`/${this.props.context.langKey}/`}>
                <Image 
                  src={`https://wakatoon.cdn.prismic.io/wakatoon/${this.props.logo}`}
                  alt={this.props._t(`Wakatoon - Le Coloriage Animé`)}
                  force
                />
              </Link>
            </div>
            <MainMenu ui="desktop" {...this.props} />
          </div>
        </div>

        <div className={Style.containerMobile}>
          <Announcement context={this.props.context} />
          <div className={`${Style.innerMobile} row no-padding`}>
            <div className="col-6 no-padding">
              <div className={Style.logoMobile}>
                <Link to={`/${this.props.context.langKey}/`}>
                  <Image
                    src={`https://wakatoon.cdn.prismic.io/wakatoon/${this.props.logo}`}
                    alt={this.props._t(`Wakatoon - Le Coloriage Animé`)}
                    force
                  />
                </Link>
              </div>
            </div>
            <div className={`${Style.innerMobile} ${Style.mobileMenuWrapper} col-6 no-padding`}>
              <MainMenu ui="mobile" {...this.props} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
};

const mapStateToProps = (state) => ({ 
  ...state.menu,
  menu: state.menu,
  cart: state.cart,
  viewportSize: state.viewport.viewportSize
});
const mapDispatchToProps = (dispatch) => ({
  handleCartOpen: () => dispatch(handleCartOpen()),
  handleBackgroundMenuActive: (state) => dispatch(handleBackgroundMenuActive(state)),
  handleSubMenuOpened: (state) => dispatch(handleSubMenuOpened(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);