
module.exports = {
  'fr': {
    'howdoesitwork': [
      'dc4a2e7a-681a-4d70-8654-09dd288e83de_marsu.jpg'
    ],
    'shop': [
      '92424874-84bb-4df3-8d14-1eb28a2cc4d8_bill.jpg',
      '530b31ab-6d69-4942-9bc0-b31eeb16ee02_birds.jpg'
    ],
    'education': [
      'a0bb29ea-c331-48e6-806d-43b12b42ea2f_characters.jpg',
      '1fd0b796-ac57-4162-abd0-9abf7ee65132_animals.jpg'
    ]
  },
  'en': {
    'howdoesitwork': [
      'dc4a2e7a-681a-4d70-8654-09dd288e83de_marsu.jpg'
    ],
    'shop': [
      '92424874-84bb-4df3-8d14-1eb28a2cc4d8_bill.jpg',
      '530b31ab-6d69-4942-9bc0-b31eeb16ee02_birds.jpg'
    ],
    'education': [
      'a0bb29ea-c331-48e6-806d-43b12b42ea2f_characters.jpg',
      '1fd0b796-ac57-4162-abd0-9abf7ee65132_animals.jpg'
    ]
  }
}