/**
 * @todo refactoring
 */

import React from 'react'
import * as EmailValidator from 'email-validator'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import ModalNewsletter from './Modal/ModalNewsletter'
import Style from '../styles/components/newsletter.module.scss'

const Form = ({ status, message, onValidated, _t }) => {
  let email;
  const t = (str) => _t[_t.findIndex(item => item.key_text === str)].text.text;
  const submit = () => {
    email &&
    email.value.indexOf("@") > -1 && 
    EmailValidator.validate(email.value) && 
    onValidated({
      EMAIL: email.value
    });
  }
  
  return (
    <React.Fragment>
      <div className={`${Style.container}`}>
        <input
          ref={node => (email = node)}
          type="email"
          placeholder={t('Mon email')}
        />
        <button
          disabled={status === 'sending'}
          onClick={submit}
          className={`${Style.newsletterRegister} btn btn_blue tiny invert no-margin`}
          id="waka-newsletter-register"
        >
          {status === 'sending' ? t(`En cours`) : t(`Inscrivez-vous`)}
        </button>

        {status !== null && (
          <ModalNewsletter
            class={status}
            message={status === 'sending' ? t('Envoi en cours') : message}
            _t={_t}
          />
        )}
      </div>
    </React.Fragment>
  )
};

const Newsletter = (props) => {
  return (
    <MailchimpSubscribe
      url={props.api.post}
      render={({ subscribe, status, message }) => {
        return (
          <Form
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            {...props}
          />
        )
      }}
    />
  )
};

export default Newsletter;