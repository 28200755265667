import React from 'react';
import { Link } from 'gatsby'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Header from './Header';
// import Cart from './Shopify/Cart';
import ModalPopUp from './Modal/ModalPopUp';
import ModalPopUpCta from './Modal/ModalPopUpCta';
import ModalBanner from './Modal/ModalBanner';
import Footer from './Footer';
import { handleViewportSize } from '../actions/responsive';
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { setHomepage } from '../actions/homepage';

class App extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.container = React.createRef();
    const path = props.path === `/${props.lang}/` || props.path === `/telecharger/` || props.path === `/download/`
    props.setHomepage(path);
  }
  
  componentDidMount() {
    const parentNode = ReactDOM.findDOMNode(this).parentNode
    this.container = parentNode.querySelector('.Layout');
    setTimeout(() => {
      this.getSize();
    }, 500);
    window.addEventListener('resize', this.getSize);
  }

  handleViewportSize = (state) => {
    this.props.handleViewportSize(state);
  }
  
  getSize = () => {
    const viewport = {
      size: 'large'
    }
    if (this.container !== null) {
      if (this.container.clientWidth !== undefined) {
        if (this.container.clientWidth < 768 ) {
          viewport.size = 'small';
        }  else if (this.container.clientWidth < 1024) {
          viewport.size = 'medium';
        }

        if (window.innerHeight < 415 && window.innerWidth <= 812) {
          viewport.size = 'small-height';
        }
        this.handleViewportSize(viewport.size);
        return viewport;
      }
    }
  }
  
  _t = (str) => {
    return this.props._t[this.props._t.findIndex(item => item.key_text === str)].text.text;
  };
  
  render() {
    const { context, _t } = this.props;
    return (
      <div
        id="Layout"
        className={`Layout Layout__Wakatoon-default content-ready`}
        ref={el => (this.container = el)}
      >
        <div>
          <Header
            siteTitle={this.props.title}
            path={this.props.path}
            logo={this.props.logo}
            context={this.props.context}
            hideMenu={this.props.hideMenu}
            collaborationsFilters={this.props.collaborationsFilters}
            _t={this._t}
          />
          
          {/**
          * @deprecated
          * https://wakatoon.atlassian.net/browse/WS-220
          */}
          {/* <Cart _t={_t} /> */}

          {this.props.children}
          
          <ModalPopUp
            api={{
              key: this.props.context.WAKATOON_MAILER_PUBLIC_KEY,
              endpoint: this.props.context.WAKATOON_STORIES_PRINTABLES_ENDPOINT,
            }}
            context={context}
            _t={_t}
          />

          <ModalPopUpCta
            api={{
              key: this.props.context.WAKATOON_MAILER_PUBLIC_KEY,
              endpoint: this.props.context.WAKATOON_REQUESTS_ENDPOINT,
            }}
            context={context}
            _t={_t}
          />

          <ModalBanner />

          <Footer
            hideMenu={this.props.hideMenu}
            isDownloadPage={this.props.isDownloadPage}
            {...context}
            api={{
              post: this.props.context.MAILCHIMP_POST_URL,
            }}
            _t={_t}
          />

          <CookieConsent
            location="bottom"
            buttonClasses="btnCookie"
            enableDeclineButton
            declineButtonClasses="btnCookie"
            buttonText={this._t('Accepter')}
            declineButtonText={'Refuser'}
            style={{ background: '#2B373B' }}
            cookieName="gatsby-gdpr-google-analytics"
            onAccept={() => {
              Cookies.set('gatsby-gdpr-google-tagmanager', true);
              Cookies.set('gatsby-gdpr-facebook-pixel', true);
              if (typeof window !== 'undefined') {
                window.__gdpr = true;
              }
            }}
          >
            <p>
              {this._t('Ce site utilise des cookies pour améliorer votre expérience.')}
              <br /><small>
                <Link 
                  to={`${context.langKey === 'fr' ? '/fr/politique-de-confidentialité/' : '/en/privacy-policy/'}`}
                  style={{color: 'white', textDecoration: 'underline'}}
                >
                  {`${context.langKey === 'fr' ? 'Politique de confidentialité' : 'Privacy policy'}`}
                </Link>
              </small>
            </p>
          </CookieConsent>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.homepage,
  ...{ viewportSize: state.viewport.viewportSize }
});
const mapDispatchToProps = (dispatch) => ({
  handleViewportSize: (state) => dispatch(handleViewportSize(state)),
  setHomepage: (state) => dispatch(setHomepage(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
