import React from 'react';
import prev from "../../assets/images/prev.png";
import next from "../../assets/images/next.png";
import prev_banner from "../../assets/images/prev_banner.png";
import next_banner from "../../assets/images/next_banner.png";

export const CustomPrevArrow = (props) => {
  const { className, onClick, type } = props;
  const imageButton = type === 'banner' ? prev_banner : prev;
  return (
    <button
      type="button"
      className={className}
      onClick={onClick}
    >
      <img src={imageButton} alt="prev" />
    </button>
  )
}

export const CustomNextArrow = (props) => {
  const { className, onClick, type } = props;
  const imageButton = type === 'banner' ? next_banner : next;
  return (
    <button
      type="button"
      className={className}
      onClick={onClick}
    >
      <img src={imageButton} alt="next" />
    </button>
  )
}