// import React from 'react'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Style from '../styles/components/announcement.module.scss'

const AnnouncementContainer = ({isOpen, change, context: {locale}}) => {
  const { prismicContent } = useStaticQuery(
    graphql`
      query {
        prismicContent: allPrismicAnnouncement {
          nodes {
            lang
            data {
              enabled
              background_color
              text_color
              message {
                html
              }
            }
          }
        }
      }
    `
  );

  const localized = prismicContent.nodes.findIndex(i => i.lang === locale);
  const content = prismicContent.nodes[localized].data;
  return (
    <div className={Style.container} style={{
      display: `${(content.enabled && isOpen) ? 'block' : 'none'}`,
      background: `${content.background_color}`,
      color: `${content.text_color}`
    }}>
      <div className={Style.close}>
        <span onClick={change}>x</span>
      </div>
      <div
        className={Style.message}
        dangerouslySetInnerHTML={{ __html: content.message.html }}
      ></div>
    </div>
  )
}

class Announcement extends React.Component {
  
  state = {
    loading: true,
    isOpen: true
  }

  componentDidMount() {
    if (typeof sessionStorage !== 'undefined') {
      const annoucementStatus = JSON.parse(sessionStorage.getItem('annoucement'));
      if (annoucementStatus === false) {
        this.setState({ isOpen: annoucementStatus, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  change = () => {
    this.setState({ isOpen: !this.state.isOpen });
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.setItem('annoucement', false);
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          !this.state.loading && (
            <AnnouncementContainer 
              change={this.change} 
              isOpen={this.state.isOpen} 
              context={this.props.context}
            />
          )
        }
      </React.Fragment>
    ) 
  }
}

export default Announcement;