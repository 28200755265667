import React from 'react';
import Modal from 'react-modal';
import Close from '../../assets/images/close.png'

class ModalNewsletter extends React.Component {

  constructor() {
    super();
    this.state = {
      modalIsOpen: true
    };
  }

  handleOpenModal = () => {
    this.setState({ modalIsOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
  }

  _t = (str) => {
    return this.props._t[this.props._t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        contentLabel="Newletter"
        closeTimeoutMS={0}
        className="modal modal-newsletter"
        ariaHideApp={false}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        <div 
          className="close-modal" 
          onClick={this.handleCloseModal}
          style={{backgroundImage:`url(${Close})`}}
        ></div>
        <div className="modal-container">
          <p className="modal__body">
            {this._t('Inscription à la newsletter')}
            <span 
              className={`messages messages-${this.props.class}`}
              dangerouslySetInnerHTML={{ __html: this.props.message }}
            ></span>
          </p>
        </div>
      </Modal>
    );
  }
}

export default ModalNewsletter;