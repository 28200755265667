import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'gatsby'
import Menui18n from '../../config/menui18n'
import DropdownMenu from './DropdownMenu'
import { LocaleSwitch } from './SwitchLocale'
import SwitchLocaleMenu from './SwitchLocaleMenu'
// import cart from '../assets/images/cart.png'
// import cartb from '../assets/images/cart_b.png'
import Style from '../styles/components/nav.module.scss'

export default class extends React.Component {

  constructor() {
    super()
    this.state = {
      subMenuActive: false,
      mobileNavActive: false
    }
  }

  componentDidMount() {
    this.SubMenu = this.refs.SubMenu;
  }

  handleSubMenu = (e) => {
    let active = false;
    if (typeof this.SubMenu !== 'undefined') {
      active = this.SubMenu.handleSubMenu(e.target.dataset.slug);
    }
    this.setState({ subMenuActive: active });
    this.props.handleBackgroundMenuActive(window.scrollY > 50 || active);
  }

  handleSubMenuLocale = (e) => {
    this.setState({ subMenuActive: !this.state.subMenuActive });
  }

  render() {
    const menuItems = Menui18n[this.props.context.langKey || 'fr'].filter((item) => typeof item.isFooterLink === 'undefined');
    const path = typeof this.props.path !== 'undefined' ? this.props.path.split('/') : [];
    let activePath = null;
    if (path.length >= 3 && path[2] !== "") {
      activePath = `${path[1]}/${path[2]}`
    }
    return (
      <React.Fragment>
        { this.props.ui === 'desktop' ? (
          <React.Fragment>
            <nav className={Style.desktopNav} style={{ width: `${this.props.hideMenu ? '80%' : 'auto'}` }}>
              <ul itemScope itemType="http://www.schema.org/SiteNavigationElement">
                {
                  !this.props.hideMenu && (
                    <React.Fragment>
                      { menuItems.map((item, key) => {
                        return (
                          <li itemProp="name" key={`menu-${key}`}>
                            {
                              (item.id === 'collaborations' || item.id === 'shop') ? (
                                <React.Fragment>
                                  {
                                    item.id === 'shop' && (
                                      <a
                                        className={activePath === item.slug ? Style.active : ''}
                                        href="https://shop.wakatoon.com/"
                                        itemProp="url"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-slug={item.slug}
                                        // onMouseOver={this.handleSubMenu}
                                      >
                                        {item.label}
                                      </a>
                                    )
                                  }
                                  {
                                    item.id === 'collaborations' && (
                                      <a
                                        className={activePath === item.slug ? Style.active : ''}
                                        href={`/${item.slug}/`}
                                        itemProp="url"
                                        data-slug={item.slug}
                                        onMouseOver={this.handleSubMenu}
                                      >
                                        {item.label}
                                      </a>
                                    )
                                  }
                                </React.Fragment>
                              ) : (
                                <Link
                                  activeClassName={Style.active}
                                  className={activePath === item.slug ? Style.active : ''}
                                  to={`/${item.slug}/`}
                                  itemProp="url"
                                  data-slug={item.slug}
                                  onMouseOver={this.handleSubMenu}
                                >
                                  {item.label}
                                </Link>
                              )
                            }
                          </li>
                        )
                      })}

                      {/**
                      * @deprecated
                      * https://wakatoon.atlassian.net/browse/WS-220
                      */}
                      {/* {
                        this.props.context.langKey !== 'en' && (
                          <li>
                            <div className={`${Style.viewCartWrapper} wakabucket`}>
                              <button 
                                className={`${Style.viewCart} wakabucket`} 
                                onClick={() => this.props.handleCartOpen()}
                              >
                                <img src={cart} alt="Mon panier" className="wakabucket" />
                                {
                                  this.props.cart.checkout ? (
                                    <span>{this.props.cart.checkout.lineItems.length}</span>
                                  ) : (
                                    <span>0</span>
                                  )
                                }
                              </button>
                            </div>
                          </li>
                        )
                      } */}
                    </React.Fragment>
                  )
                }
                <li>
                  <LocaleSwitch handleSubMenu={this.handleSubMenu} ui="desktop" context={this.props.context} />
                </li>
              </ul>
            </nav>
            <DropdownMenu 
              ref="SubMenu" 
              items={menuItems} 
              locale={this.props.context.locale} 
              lang={this.props.context.langKey} 
              handleBackgroundMenuActive={this.props.handleBackgroundMenuActive} 
              handleSubMenuOpened={this.props.handleSubMenuOpened}
              context={this.props.context}
              collaborationsFilters={this.props.collaborationsFilters}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* {
              !this.props.hideMenu && (
                <React.Fragment>
                  {
                    this.props.context.langKey !== 'en' && (
                      <div className={`${Style.viewCartWrapper} ${Style.mobileNav}`}>
                        <button 
                          className={Style.viewCart} 
                          onClick={() => this.props.handleCartOpen()}
                        >
                          <img src={cartb} alt="Mon panier" className="wakabucket" />
                          {
                            this.props.cart.checkout ? (
                              <span>{this.props.cart.checkout.lineItems.length}</span>
                            ) : (
                              <span>0</span>
                            )
                          }
                        </button>
                      </div>
                    )
                  }
                </React.Fragment>
              )
            } */}
            <div>
              <LocaleSwitch handleSubMenu={this.handleSubMenuLocale} ui="mobile" context={this.props.context} />
            </div>
            <div className={Style.localeMobileNav} style={{display: `${this.state.subMenuActive ? 'block' : 'none'}`}}>
              <SwitchLocaleMenu ui="mobile" handleSubMenu={this.handleSubMenuLocale} context={this.props.context} />
            </div>
            {
              !this.props.hideMenu && (
                <div className={Style.mobileNav}>
                  <Menu right isOpen={this.state.mobileNavActive}>
                    <ul>
                      { menuItems.map((item, key) => {
                        return (
                          <li key={`menu-${key}`}>
                            <Link to={`/${item.slug}/`}>
                              {item.label}
                            </Link>
                            {/* { typeof item.pages !== 'undefined' && 
                              <ul className={Style.submenuMobile}>
                                { item.pages.map((page, index) => {
                                  return (
                                    <li key={`submenumobile-${index}`}>
                                      {typeof page.dist === 'undefined' ? (
                                        <Link 
                                          to={page.parent 
                                            ? `/${page.parent}/${page.slug}/` 
                                            : `/${page.slug}/`
                                          }
                                        >
                                          {page.label}
                                        </Link>
                                      ) : (
                                        <a 
                                          href={`${page.dist}`} 
                                          target="_blank" 
                                          rel="noopener noreferrer"
                                        >
                                          {page.label}
                                        </a>
                                      )}
                                    </li>
                                  )
                                })}
                              </ul>
                            } */}
                          </li>
                        )
                      })}
                    </ul>
                  </Menu>
                </div>
              )
            }
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
