
export const handleModal = (state) => ({
  type: 'TOGGLE_MODAL',
  ...state
});

export const handleModalCta = (state) => ({
  type: 'TOGGLE_MODAL_CTA',
  ...state
});

export const handleModalLanding = (state) => ({
  type: 'TOGGLE_MODAL_LANDING',
  ...state
});

export const handleModalBanner = (state) => ({
  type: 'TOGGLE_MODAL_BANNER',
  ...state
});