import React from 'react'
import { connect } from 'react-redux'
import { handleChangeLocale } from '../actions/locale'
import Style from '../styles/components/locale.module.scss'
import { StaticQuery, graphql, navigate } from 'gatsby'
import Menui18n from '../../config/menui18n'

import Checked from '../assets/images/coche.png'
import Close from '../assets/images/close-locale.png'

const languages = {
  'en': { label: 'English', locale: 'en-us' },
  'fr': { label: 'Français', locale: 'fr-fr' }
}

const SwitchLocaleMenu = ({ 
  context: { langKey },
  handleChangeLocale, 
  ui, 
  handleSubMenu, 
  context 
}) => {
  const isBlog = context.slug && context.slug.match(/blog/i);
  const changeLocale = (locale) => {
    if (typeof window !== 'undefined') {
      const newLang = locale.split('-')[0];
      const redirect = context.alt_languages && context.alt_languages.filter((item) => {
        return (item.locale === locale || item.langKey === newLang);
      });
      const l18n = Menui18n[newLang];

      let baseSlug, subSlug, slug;
      if (typeof redirect === 'undefined' || !redirect) {
        baseSlug = context.uid && l18n[l18n.findIndex(i => i.id === context.uid)];
        subSlug = context.parent && l18n[l18n.findIndex(i => i.id === context.parent)].pages[
          l18n[l18n.findIndex(i => i.id === context.parent)].pages.findIndex((i) => {
          return i.id === context.uid
        })];
        slug = typeof baseSlug !== 'undefined' ? baseSlug.slug : (typeof subSlug !== 'undefined' && subSlug.slug)
      }

      handleChangeLocale(locale);
      if (slug && typeof slug !== 'undefined') {
        navigate(`/${slug}/`);
      } else if (window.location.pathname === `/${langKey}/` || (typeof redirect === 'undefined' || !redirect)) {
        navigate(`/${newLang}/`);
      } else {
        navigate(`/${redirect[0].path}/`);
      }
    }

    if (ui === 'mobile') {
      handleSubMenu();
    }
  }
  
  return (
    <StaticQuery
      query={graphql`
        query {
          allflags: allFile(filter: { relativeDirectory: { eq: "locale" } }) {
            edges {
              node {
                name
                childImageSharp {
                  fixed(width: 29, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allflags: { edges: items } }) => {
        let __items = items;
        let _items = isBlog ? [] : items;
        if (isBlog) {
           __items.forEach((item) => {
             const match = item.node.name.match(/fr/i);
             if (match !== null) {
               _items.push(item);
             }
           })
        }
        const flagsList = _items.filter((item) => {
          return item.node.name.match(`${ui}_off`);
        });
        const active = _items.filter((item) => {
          return item.node.name === `flag_${langKey || 'fr'}_${ui}_on`
        });
        
        return (
          <div className={Style.localesContainer}>
            {
              ui === 'mobile' && (
                <div 
                  className="close-locale" 
                  onClick={handleSubMenu}
                  style={{backgroundImage:`url(${Close})`}}
                >
                </div>
              )
            }
            {
              flagsList.map((flag, index) => {
                const flagLang = flag.node.name.match(/_[a-z]{2}_/i)[0].replace(/_/g, '');
                const flagSrc = flag.node.childImageSharp.fixed.src;
                const currentFlagSrc = active[0].node.childImageSharp.fixed.src;
                const isActive = flagLang === langKey;
                return (
                  <div key={`locale_${index}`} className={Style.localeContainer}>
                    {
                      (flagLang === langKey && ui === 'desktop') && (
                        <img src={Checked} alt={langKey} />
                      )
                    }
                    {
                      ui === 'desktop' && (
                        <span
                          data-lang={flagLang}
                          style={{cursor: `${isActive ? 'default' : 'pointer'}`}}
                          onClick={isActive
                            ? () => console.log('nothing')
                            : () => changeLocale(languages[flagLang].locale)
                          }
                        >
                          {languages[flagLang].label}
                        </span>
                      )
                    }
                    <button 
                      className={Style.localeSwitchButton} 
                      data-lang={flagLang}
                      style={{cursor: `${isActive ? 'default' : 'pointer'}`}}
                      onClick={isActive
                        ? () => console.log('nothing')
                        : () => changeLocale(languages[flagLang].locale)
                      }
                    >
                      <img src={flagLang === langKey ? currentFlagSrc : flagSrc} alt={languages[flagLang].label} />
                    </button>
                  </div>
                )
              })
            }
          </div>
        )
      }}
    />
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleChangeLocale: (locale) => dispatch(handleChangeLocale(locale))
});

export default connect(null, mapDispatchToProps)(SwitchLocaleMenu);
